import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isNumber, isValidInput } from "../../utils/validations";
import { showError, showToast } from "../../constants";
import {
  useLazyChallengePointsQuery,
  useLazyChallengeStatusQuery,
  useLazyDeleteChallengeQuery,
  useLazyGetChallengesQuery,
  useUpdatePointsMutation,
} from "../../services/advertiser";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import useTranslation from "../../hooks/Translation";
import { Challenge } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageChallenges = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [deleteById] = useLazyDeleteChallengeQuery();
  const [challengeStatus] = useLazyChallengeStatusQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [challenge, setChallenge] = useState<Challenge[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [getChallenges, { isLoading }] = useLazyGetChallengesQuery();
  const [points, setPoints] = useState<number>(0);
  const [pointsId, setPointsId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  const [getPoints] = useLazyChallengePointsQuery();
  const [updatePoints] = useUpdatePointsMutation();

  const updateChallengePoints = async () => {
    try {
      const body = {
        points: points,
        appKey: new Date().toISOString(),
      };
      console.log(body, "sdsdsdsd");
      const response: any = await updatePoints({
        id: pointsId,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        fetchPoints();
        showToast("Points updated successfully");
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchPoints = async () => {
    try {
      const response: any = await getPoints({}).unwrap();
      if (response?.statusCode === 200) {
        setPoints(response?.data?.points);
        setPointsId(response?.data?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Challenges"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  const fetchChallenges = async () => {
    try {
      const response: any = await getChallenges({
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      });
      console.log(response?.data, "resss");
      if (response?.data?.statusCode === 200) {
        setChallenge(response?.data?.data?.Challenges);
        setTotalCount(response?.data?.data?.count);
      } else {
        setChallenge([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchChallenges();
  }, [searchTerm, debouncedSearchTerm, page]);

  useEffect(() => {
    fetchPoints();
  }, []);

  const translations = useTranslation() as any;
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_challenge.manage_challenge || ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <Box className="cards_header_right chllng_point">
              {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                <>
                  <Box>
                    <Typography className="custom_label">
                      {translations.manage_challenge.challenge_points || ""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      value={points}
                      placeholder="Points"
                      onChange={(e) => {
                        let value = e.target.value;
                        const input = Number(value);
                        setPoints(input);
                      }}
                    />
                  </Box>
                  <Button
                    className="btn btn_primary"
                    onClick={() => {
                      updateChallengePoints();
                    }}
                  >
                    {translations.manage_challenge.add || ""}
                  </Button>
                  <Button
                    className="btn btn_primary"
                    onClick={() => {
                      navigate("/manage-challenges/add");
                    }}
                  >
                    {translations.Globals.create_challenge || ""}
                  </Button>
                </>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translations.table_heads.sno || ""}
                  </TableCell>
                  <TableCell>{translations.table_heads.title || ""}</TableCell>
                  <TableCell>{translations.table_heads.desc || ""}</TableCell>
                  <TableCell>
                    {translations.table_heads.allowed_entries || ""}
                  </TableCell>
                  <TableCell>{translations.Globals.created_by || ""}</TableCell>
                  <TableCell>{translations.table_heads.status || ""}</TableCell>
                  <TableCell>
                    {translations.table_heads.actions || ""}
                  </TableCell>
                </TableRow>
              </TableHead>
              {challenge?.length ? (
                challenge?.map((item: any, index: any) => (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell>
                        {item?.title.length > 10
                          ? `${item.title.substring(0, 15)}...`
                          : item?.title}
                      </TableCell>
                      <TableCell>
                        {item?.description.length > 10
                          ? `${item.description.substring(0, 15)}...`
                          : item?.description}
                      </TableCell>
                      <TableCell>{item?.noOfEntries}</TableCell>
                      <TableCell>{item?.isAdmin ? "Admin" : "User"}</TableCell>
                      <TableCell>
                        <Switch
                          onChange={() => {
                            updateStatus(
                              item?._id,
                              !item?.isBlocked,
                              challengeStatus,
                              setChallenge,
                              challenge
                            );
                          }}
                          {...label}
                          checked={!item?.isBlocked}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(
                                "/manage-challenges/details/" + item?._id,
                                {
                                  state: { page: "navigate" },
                                }
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              navigate("/manage-challenges/edit/" + item?._id)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(item?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    {translations.manage_challenge.not_found || ""}
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={challenge}
        onPageChange={onPageChange}
        totalPages={totalPages}
        page={page}
      />

      <WarnModal
        setOpen={setOpen}
        open={open}
        name={translations.Globals.challenge}
        handleDelete={() =>
          handleDelete(deleteById, selectedId, fetchChallenges)
        }
      />
    </MainContainer>
  );
};

export default ManageChallenges;
