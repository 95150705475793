export const ar = {
  Globals: {
    Login: "تسجيل الدخول",
    Continue: "متابعة",
    sign_in: "الدخول",
    sign_up: "التسجيل",
    export_csv: "تصدير ملف بصيغة قيم مفصولة بفواصل (CSV)",
    back: "إلى الخلف",
    save: "حفظ",
    send: "إرسال",
    submit: "تقديم",
    select: "تحديد",
    //new
    login_to: "تسجيل الدخول إلى المشرف الطموح",
    email_address: "عنوان البريد الإلكتروني",
    password: "كلمة المرور",
    myProfile: "ملفي",
    change_password: "تغيير كلمة المرور",
    logout: "تسجيل خروج",
    old_password: "كلمة المرور القديمة",
    new_password: "كلمة المرور الجديدة",
    confirm_password: "تأكيد كلمة المرور",
    remember_me: "تذكرنى",
    forgot_password: "كلمة المرور",
    email_required: "البريد الالكتروني مطلوب!",
    valid_email: "أدخل عنوان بريد إلكتروني صالح!",
    password_required: "كلمة المرور مطلوبة!",
    valid_password: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
    want_to_logout: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    yes: "نعم",
    no: "لا",
    search: "ابحث هنا",
    showing: "عرض",
    items: "أغراض",
    delete_this: "هل أنت متأكد أنك تريد حذف هذا",
    deleted_successfully: "حذف بنجاح",
    edit_profile: "تحرير ملف تعريف المستخدم",
    view_profile: "عرض ملف تعريف المستخدم",
    address: "عنوان",
    active: "نشيط",
    blocked: "محظور",
    accept: "قبلت",
    pending: "قيد الانتظار",
    rejected: "مرفوض",
    advertiser: "معلن",
    full_name_req: "الإسم الكامل ضروري",
    min_2: "مطلوب حرفين على الأقل",
    max_80: "الحد الأقصى المسموح به هو 80 حرفًا",
    phone_required: "رقم الهاتف مطلوب",
    phone_6: "يجب أن لا يقل رقم الهاتف عن 6 أحرف",
    phone_16: "يجب أن لا يقل رقم الهاتف عن 16 حرفًا",
    address_req: "العنوان مطلوب",
    max_100: "الحد الأقصى المسموح به هو 100 حرف",
    city_req: "المدينة مطلوبة",
    state_req: "الدولة مطلوبة",
    country_req: "الدولة مطلوبة",
    field_req: "هذه الخانة مطلوبه",
    min_4: "مطلوب 4 أحرف على الأقل",
    max_12: "الحد الأقصى المسموح به هو 12 حرفًا",
    front_img_req: "الصورة الأمامية للوثيقة مطلوبة",
    back_img_req: "مطلوب صورة الخلفية للوثيقة",
    advertiser_update: "تم تحديث المعلن بنجاح!",
    advertiser_create: "تم إنشاء المعلن بنجاح!",
    no_stories: "لم يتم العثور على قصص",
    category_name_req: "اسم الفئة مطلوب",
    category_update: "تم تحديث الفئة بنجاح!",
    category_create: "تم إنشاء الفئة بنجاح!",
    image: "صورة",
    category_name_en: " اسم الفئة (الإنجليزية)",
    category_name_ar: "اسم الفئة (عربي)",
    edit_category: "تحرير فئة التحدي",
    add_category: "أضف فئة التحدي",
    plan_type: "حدد نوع الخطة",
    feature: "حدد ميزة واحدة على الأقل",
    subs_update: "تم تحديث الاشتراك",
    subs_add: "تمت إضافة الاشتراك",
    Half_Yearly: "نصف سنوي",
    basic: "لا يمكن حذف الخطة الأساسية",
    basic_edit: "الخطة الأساسية غير قابلة للتحرير",
    no_subs: " لم يتم العثور على أي اشتراكات",
    subs_plan: "خطة الاشتراك",
    min_1: "مطلوب حرف واحد على الأقل",
    rewards_update: "تم تحديث المكافآت بنجاح",
    reward: "جائزة",
    no_reward: "لم يتم العثور على مكافآت",
    cancel: "يلغي",
    compalaint: "شكوى",
    no_allowed: "عدد الإدخالات المسموح بها",
    update: "تم التحديث بنجاح",
    img_vid: "لا يمكن أن تكون الصورة أو الفيديو فارغة",
    select_img: "يرجى تحديد الصور ومقاطع الفيديو للتحميل",
    challenge: "تحدي",
    word_update: "تم تحديث الكلمة بنجاح!",
    word_create: "تم إنشاء الكلمة بنجاح!",
    word: "كلمة",
    gift_update: "تم تحديث الهدية بنجاح",
    gift_add: "تمت إضافة الهدية بنجاح",
    gift: "هدية",
    notification: "إشعار",
    select_not: "حدد المستخدم أو حدد خانة الاختيار",
    notification_send: "تم إرسال الإشعار بنجاح",
    receiver: " حدد جهاز الاستقبال",
    fname_req: "الإسم الأول مطلوب",
    min_3: "مطلوب 3 أحرف على الأقل",
    max_30: "الحد الأقصى المسموح به هو 30 حرفًا",
    allow_permission: "يرجى السماح بالإذن لوحدة واحدة على الأقل",
    subadmin_update: "تم تحديث المشرف الفرعي بنجاح",
    subadmin_create: "تم إنشاء المشرف الفرعي بنجاح",
    phone: "رقم التليفون",
    old: "كلمة المرور القديمة",
    old_req: "كلمة المرور القديمة مطلوبة.",
    new: "كلمة المرور الجديدة",
    new_req: "كلمة المرور الجديدة مطلوبة.",
    cannot: "كلمة المرور الجديدة لا يمكن أن تكون نفس كلمة المرور القديمة.",
    match: "يجب أن تتطابق كلمات المرور.",
    confirm: "تأكيد كلمة المرور مطلوب",
    pw_change: "تم تغيير الرقم السري بنجاح",
    new_pw: "كلمة المرور الجديدة",
    cnfrm: "تأكيد كلمة المرور",
    change: "تغيير كلمة المرور",
    verify_otp: "التحقق من كلمة المرور لمرة واحدة (OTP).",
    resend: "إعادة إرسال كلمة المرور لمرة واحدة",
    expire: "ستنتهي صلاحية رمز التحقق في",
    reset_pw: "إعادة تعيين كلمة المرور",
    dashboard: "لوحة القيادة",
    manage_user: "ادارة المستخدمين",
    manage_Advertiser: "إدارة المعلنين",
    manage_advertisement: "إدارة الإعلانات",
    manage_content: "إدارة المحتوى/المشاركات",
    manage_journey: "إدارة الرحلة",
    manage_challenge: "إدارة فئات التحدي",
    manage_subs: "إدارة خطط الاشتراك",
    manage_rewards: "إدارة المكافآت",
    customer: "دعم العملاء",
    manage_challenge_dash: "إدارة التحديات",
    manage_word: "إدارة الكلمات",
    manage_payment: "إدارة المدفوعات",
    manage_gift: "إدارة الهدايا",
    manage_notification: "إدارة الإخطارات",
    report: "التقارير والتحليلات",
    manage_subadmin: "إدارة المشرف الفرعي",
    cms: "إدارة نظام إدارة المحتوى",
    faq: "إدارة الأسئلة الشائعة",
    subadmin: "مشرف فرعي",
    enable: "تم التمكين بنجاح",
    no_ads: " لم يتم العثور على دفعة الإعلانات",
    faq_add: "تمت إضافة الأسئلة الشائعة بنجاح",
    faq_del: "تم حذف الأسئلة الشائعة بنجاح!",
    no_adver: " لم يتم العثور على معلنين",
    challenge_created: "تم إنشاء التحدي بنجاح",
    create_challenge: "خلق التحدي",
    add_journey: "أضف رحلة",
    only_video: "يمكنك تحميل الفيديو فقط",
    journey_video: "تحميل فيديو الرحلة",
    title: "عنوان",
    description: "وصف",
    location: "موقع",
    journey_type: "نوع الرحلة",
    category: "فئة",
    private: "خاص",
    public: "عام",
    target_date: "تاريخ التسليم",
    video_too_long:
      "الفيديو طويل جدًا. الرجاء إضافة فيديو مدته أقل من 30 ثانية",
    journey_created: "تم إنشاء الرحلة بنجاح",
    targetDate: "تاريخ التسليم",
    cat_req: "اختيار القسم مطلوب",
    upload_video: "يرجى تحميل فيديو الرحلة",
    all_journeys: "جميع الرحلات",
    created_by: "انشأ من قبل",
    liveStreaming: "تاريخ البث المباشر",
    comments_enabled: "التعليقات ممكّنة",
    noOfUsers: "عدد المستخدمين المنضمين",
    no_live_history: "لم يتم العثور على سجل البث المباشر",
    received_notifications: "الإخطارات المستلمة",
  },

  warnmodal: {
    desc: "هل أنت متأكد من أنك تريد حذف هذا",
  },

  toast: {
    enabled: "تم التمكين بنجاح ",
    disabled: "تم التعطيل بنجاح",
  },

  table_heads: {
    sno: "الرقم المسلسل",
    username: "اسم المستخدم",
    phone: "رقم الهاتف",
    phone_number: "رقم الهاتف",
    payment_received: "الدفعة المستلمة",
    payment_mode: "طريقة الدفع",
    email: "البريد الإلكتروني",
    fname: "الاسم الكامل",
    account_status: "حالة الحساب",
    actions: "إجراءات",
    journey_type: "نوع الرحلة",
    bucket_title: "عنوان المجموعة",
    category: "الفئة",
    status: "الحالة",
    img_vid: "الصورة/ الفيديو",
    challenge_title: "عنوان التحدي",
    location: "الموقع",
    post: "المنشور",
    post_description: "وصف المنشور",
    date_time: "تحميل التاريخ والوقت",
    story: "القصة",
    text: "النص",
    duration: "المدة",
    contact_number: "رقم الاتصال",
    approval_status: "حالة الموافقة",
    ad_image: "صورة الإعلان",
    advertiser_details: "تفاصيل المعلن",
    ad_title: "عنوان الإعلان",
    web_link: "رابط الموقع الإلكتروني",
    created_at: "تم إنشاؤه في",
    created_Date: "تاريخ الإنشاء",
    total_cost: "التكلفة الإجمالية",
    image: "الصورة",
    created_on: "تم الإنشاء على",
    price: "السعر",
    url: "رابط الموقع (Url) ",
    user_details: "تفاصيل المستخدم",
    reported_by: "تم الإبلاغ من قبل",
    comments: "تعليق",
    report: "إبلاغ",
    likes: "الإعجابات",
    replies: "عدد الردود",
    journey: "الرحلة",
    journey_desc: "وصف الرحلة",
    upload_date: "تاريخ التحميل",
    category_name: "اسم الفئة",
    plane_name: "اسم الخطة",
    plan_validity: "نوع الخطة",
    plan_price: "سعر الخطة",
    features: "المزايا",
    badge_image: "صورة الشارة",
    name: "الاسم",
    points_earned: "النقاط المكتسبة",
    no_of_journey: "عدد الرحلات",
    no_of_post: "عدد المنشورات",
    no_of_challenge: "عدد التحديات",
    queries: "الاستعلامات",
    title: "العنوان",
    desc: "الوصف",
    allowed_entries: "الإدخالات المسموح بها",
    word: "الكلمات",
    gift_image: "صورة الهدية",
    gift_title: "عنوان الهدية",
    gift_worth: "قيمة الهدية",
    date: "التاريخ",
    delete: "حذف",
    message: "الرسالة",
  },

  dashboard: {
    dashboard: "لوحة المعلومات",
    total_users: "إجمالي المستخدمين",
    total_post: "إجمالي المنشورات",
    total_chlg: "إجمالي التحديات",
    total_journey: "إجمالي الرحلات",
    total_earning: "إجمالي الأرباح",
  },

  graph_menu: {
    users: "المستخدمين",
    earning: "الأرباح",
    daily: "اليومية",
    select: "تحديد",
    monthly: "الشهرية",
    weekly: "الأسبوعية",
    yearly: "السنوية",
    day: "اليوم",
    week: "الأسبوع",
  },

  manage_users: {
    manage_user: "إدارة المستخدمين",
    no_user_found: "لم يتم العثور على مستخدمين",
    user: "المستخدم",
  },

  manage_users_details: {
    user_details: "تفاصيل المستخدم",
    active_comp_journey: "الرحلة النشطة والمكتملة",
    active_comp_challenge: "التحديات النشطة والمكتملة",
    posts: "المنشورات",
    stories: "القصص",
  },

  manage_advertisers: {
    manage_advertiser: "إدارة المعلنين",
    add_advertisers: "إضافة معلن",
  },
  advertiser_details: {},
  add_advertiser: {
    edit: "تعديل المعلن",
    add: "إضافة معلن",
    image: "الصورة",
    fname: "الاسم الكامل",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    address: "العنوان",
    city: "المدينة",
    state: "الولاية",
    country: "الدولة",
    zip: "رمز التعريف الشخصي/الرمز البريدي",
    upload_docs: " تحميل المستندات",
    doc_name: "اسم المستند",
    expiry: "تاريخ الانتهاء",
    front: " الصورة الأمامية للمستند",
    back: " الصورة الخلفية للمستند",
  },
  manage_advertisements: {
    advertisement: "الإعلان",
    no_found: "  لم يتم العثور على إعلانات",
    approve: "موافق",
    reject: "رفض",
    select: "تحديد",
  },

  manage_advertisements_details: {
    view_details: "عرض تفاصيل الإعلان",
    image: "صورة الإعلان",
    advertiser_details: "تفاصيل المعلن",
    advertiser_name: "اسم المعلن",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    title: "العنوان",
    web_link: "رابط الموقع الإلكتروني",
    no_of_hours: "عدد الساعات",
    hourly_charge: "التكاليف بالساعة",
    total_cost: "التكلفة الإجمالية",
    created_date: "تاريخ الإنشاء",
    approval: "موافق",
    accept: "قبول",
    reject: "رفض",
    fname: "الاسم الكامل",
    approval_status: "حالة الموافقة",
    doc_name: "اسم المستند",
    expiry: "تاريخ انتهاء صلاحية المستند",
    address: "العنوان",
    ads_activated: "عدد الإعلانات النشطة",
    ads_deactivated: "عدد الإعلانات التي تم إيقاف تشغيلها",
    ads_completed: "الإعلانات المنجزة",
    docs: "المستندات",
    ads_history: "سجل الإعلانات",
  },
  manage_content: {
    all_post: "جميع المنشورات",
    reported_post: "المنشورات المبلغ عنها",
    not_found: "لم يتم العثور على منشورات",
    content_details: "عرض تفاصيل الإعلان",
    user_details: "تفاصيل المستخدم",
    user_name: "اسم المستخدم",
    phone: "رقم الهاتف",
    email: "البريد الإلكتروني",
    details: "تفاصيل المحتوى",
    uploads: "التحميلات",
    desc: "الوصف",
    upload_date: "تاريخ التحميل",
    total_comments: "إجمالي التعليقات",
    total_likes: "إجمالي الإعجابات",
    reported_comments: "التعليقات التي أبلغ عنها",
    new_comments: "التعليقات الجديدة",
    not_comments: "لم يتم العثور على تعليقات",
  },
  manage_journey: {
    manage_journey: "إدارة الرحلة",
    reported_journey: "الرحلات التي أبلغ عنها",
    not_found: "لم يتم العثور على رحلات",
    journey_details: "تفاصيل الرحلة",
    fname: "الاسم الكامل",
    userName: "اسم المستخدم",
    phone: "رقم الهاتف",
    email: "البريد الإلكتروني",
    uploads: "التحميلات",
    title: "عنوان الرحلة",
    desc: "الوصف",
    upload_date: "تاريخ التحميل",
    upload_time: "وقت التحميل",
    category: "الفئة",
    total_comments: "إجمالي التعليقات",
    total_likes: "إجمالي الإعجابات",
    share_count: "حساب عدد المشاركات",
    reported_comments: "التعليقات التي أبلغ عنها",
    new_comments: "التعليقات الجديدة",
    no_comments: "لم يتم العثور على تعليقات",
    no_replies: "لم يتم العثور على ردود",
    replies: "الردود",
    reply: "الرد",
    date: "التاريخ",
  },

  manage_categories: {
    manage_categories: "إدارة فئات التحدي",
    add_category: "إضافة فئة",
    not_found: "لم يتم العثور على فئة",
  },
  manage_subscriptions: {
    manage_subscription: "إدارة خطط الاشتراك",
    add_plan: "أضف خطة",
    add_feature: "أضف ميزة",
    cancel: "إلغاء",
    send: "إرسال",
    edit: "تعديل الخطة",
    plan_name_eng: "اسم الخطة (باللغة الإنجليزية)",
    plan_name_arb: "اسم الخطة (باللغة العربية)",
    plan_validity: "صلاحية الخطة",
    plan_type: "نوع الخطة",
    plan_price: "سعر الخطة",
    desc_eng: "الوصف (باللغة الإنجليزية)",
    desc_arb: "الوصف (باللغة العربية)",
    plan_features: "مزايا الخطة",
  },
  manage_rewards: {
    manage_rewards: "إدارة المكافآت",
    add: "إضافة شارة",
    image: "صورة",
    title_eng: "العنوان (باللغة الإنجليزية)",
    title_arb: "العنوان (باللغة العربية)",
    points_earned: "النقاط المكتسبة",
    no_of_journey: "عدد الرحلات",
    no_of_post: "عدد المنشورات",
    no_of_challenge: "عدد التحديات",
  },

  customer_support: {
    customer_support: "دعم العملاء",
    queries_received: "الاستعلامات الواردة",
    complaint_dispute: "الشكاوى/النزاعات",
    not_found: "لم يتم العثور على استعلامات",
    revert: "رجوع عن الاستعلام",
    view_details: "عرض تفاصيل الاستعلامات",
    user_details: "تفاصيل المستخدم",
    fname: "الاسم الكامل",
    phone: "رقم الهاتف",
    email: "البريد الإلكتروني",
    query_details: "تفاصيل الاستعلام",
    desc: "الوصف",
    date: "التاريخ",
    status: "الحالة",
    reply: "الرد",
  },
  manage_challenge: {
    manage_challenge: "إدارة التحديات",
    challenge_points: "نقاط التحدي",
    add: "إضافة",
    not_found: "لم يتم العثور على تحديات",
    challeneg_details: "تفاصيل التحدي",
    user_image: "صورة المستخدم",
    username: "اسم المستخدم",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    title: "العنوان",
    category: "الفئة",
    no_of_entries: "عدد الإدخالات",
    location: "الموقع",
    start_time: "وقت البدء",
    end_time: "وقت الانتهاء",
    desc: "الوصف",
    list: "قائمة التحديات",
    edit: "تعديل التحدي",
    upload: "تحميل صورة أو فيديو",
    allowed_entries: "الإدخالات المسموح بها",
    select_category: "حدد الفئة",
    start_date: "تاريخ البدء",
    end_date: "تاريخ الانتهاء",
  },
  manage_word: {
    manage_word: "إدارة الكلمات",
    add: "إضافة كلمة",
    name_eng: "الاسم (باللغة الإنجليزية)",
    name_arb: "الاسم (باللغة العربية)",
    edit: "تعديل الكلمات",
  },

  manage_payments: {
    manage_payments: "إدارة المدفوعات",
    ads: "إعلانات",
    subscription: "خطة الاشتراك",
  },
  manage_gifts: {
    manage_gifts: "إدارة الهدايا",
    add: "إضافة هدية",
    edit: "تعديل الهدية",
    not_found: "لم يتم العثور على",
    image: "صورة",
    title_eng: "العنوان (باللغة الإنجليزية)",
    title_arb: "العنوان (باللغة العربية)",
    gift_worth: "قيمة الهدية",
  },

  manage_notifications: {
    manage_notifications: "إدارة الإشعارات",
    add: "إضافة إشعار",
    not_found: "لم يتم العثور على إشعارات",
    notification_title_eng: "عنوان الإشعار (باللغة الإنجليزية)",
    notification_title_arb: "عنوان الإشعار (باللغة العربية)",
    notification_msg_eng: "رسالة الإشعار (باللغة الإنجليزية)",
    notification_msg_arb: "رسالة الإشعار (باللغة العربية)",
    receiver: "حدد المستلم",
    type: "نوع الإشعار",
    both: "كلاهما",
    email: "البريد الإلكتروني",
    select: "حدد",
    notification: "الإشعار",
    send_all: "إرسال للجميع",
  },

  analytics: {
    report_analytics: "التقارير والتحليلات",
    users: "المستخدمين",
    advertisers: "المعلنون",
    earnings: "الأرباح",
    transactions: "المعاملات",
  },
  sub_admin: {
    manage_subAdmin: "إدارة المشرف الفرعي",
    add: "إضافة مشرف فرعي",
    edit: "تعديل المشرف الفرعي",
    not_found: "لم يتم العثور على مشرف فرعي",
    image: "الصورة",
    fname: "الاسم الأول",
    lname: "الاسم الأخير",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    module: "الوحدة",
    add_edit: "إضافة/ تعديل",
    view: "عرض",
    delete: "حذف",
    dashboard: "لوحة المعلومات",
    manage_users: "إدارة المستخدمين",
    manage_advertisers: "إدارة المعلنين",
    manage_advertisements: "إدارة الإعلانات",
    manage_content: "إدارة المحتوى/المنشورات",
    manage_journey: "إدارة الرحلة",
    challenge_categories: "إدارة فئات التحديات",
    subscription_plan: "إدارة خطط الاشتراك",
    manage_rewards: "إدارة المكافآت",
    customer_support: "دعم العملاء",
    manage_challenge: "إدارة التحديات",
    manage_word: "إدارة الكلمات",
    manage_payment: "إدارة المدفوعات",
    manage_notification: "إدارة الإشعارات",
    report_analytics: "التقارير والتحليلات",
    manage_cms: "إدارة نظام إدارة المحتوى",
    manage_faq: "إدارة الأسئلة الشائعة",
  },

  manage_cms: {
    manage_cms: "إدارة نظام إدارة المحتوى",
    contact_support: "الاتصال بالدعم",
    about_us: "معلومات عنا ",
    privacy: "سياسة الخصوصية",
    term_condition: "الأحكام والشروط",
    term_condition_eng: "الشروط والأحكام (باللغة الإنجليزية)",
    term_condition_arb: "الشروط والأحكام (باللغة العربية)",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    about_us_eng: "معلومات عنا (باللغة الإنجليزية)",
    about_us_arb: "معلومات عنا (باللغة العربية)",
    privacy_eng: "سياسة الخصوصية (باللغة الإنجليزية)",
    privacy_arb: "سياسة الخصوصية (باللغة العربية)",
    support_en: "الدعم (الإنجليزية)",
    support_ar: "الدعم (العربية)",
    support: "يدعم",
    show_subscription: "إظهار الاشتراك",
  },

  faq: {
    manage_faq: "إدارة الأسئلة الشائعة",
    ques_eng: "الأسئلة (باللغة الإنجليزية)",
    ques_arb: "الأسئلة (باللغة العربية)",
    ans_eng: "الإجابة (باللغة الإنجليزية)",
    ans_arb: "الإجابة (باللغة العربية)",
    add: "إضافة",
    remove: "إزالة",
  },
};
